import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { useRouter } from "next/router";
import { FormattedMessage, useIntl } from "react-intl";
import Head from "next/head";
import { Alert, Divider } from "@mui/material";

import { useLogin } from "../api/auth";
import { useState } from "react";
import { Link } from "../components/Link";
import { AuthLayout } from "../components/AuthLayout";
import { getMetaTitle } from "../utils/misc";
import { sendEvent } from "../utils/analytics";

export default function Login() {
  const login = useLogin();
  const router = useRouter();
  const intl = useIntl();

  const [errors, setErrors] = useState<string[]>([]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const email = data.get("email") as string;
    const password = data.get("password") as string;
    const persistSession = (data.get("remember") as string) === "true";

    if (email && password) {
      login.mutate(
        { email, password, persistSession },
        {
          onSuccess: () => {
            setErrors([]);
            sendEvent("login", {
              method: "email",
            });
          },

          onError: (error) => {
            setErrors(error.errors);
          },
        }
      );
    }
  };

  React.useEffect(() => {
    if (login.data) {
      router.replace("/users");
    }
  }, [login.data, router]);

  const Error = () => {
    return (
      <Alert sx={{ mb: 1 }} severity="error">
        {errors.join("")}
      </Alert>
    );
  };

  return (
    <>
      <Head>
        <title>
          {getMetaTitle(
            intl.formatMessage({ defaultMessage: "Zaloguj się", id: "kYs7ww" })
          )}
        </title>
      </Head>
      <AuthLayout>
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlinedIcon />
        </Avatar>

        <Typography component="h1" variant="h5">
          <FormattedMessage defaultMessage="Logowanie" id="z8TlFv" />
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1 }}
          id="login-form"
        >
          {!!errors?.length && <Error />}

          <TextField
            autoFocus
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label={intl.formatMessage({
              defaultMessage: "Hasło",
              id: "ya1vft",
            })}
            type="password"
            id="password"
            autoComplete="current-password"
          />

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Link href="/reset-password" underline="hover" variant="body2">
              <FormattedMessage
                defaultMessage="Nie pamiętasz hasła?"
                id="ybguDw"
              />
            </Link>
          </Box>

          <FormControlLabel
            id="remember"
            name="remember"
            control={<Checkbox value="true" color="primary" />}
            label={intl.formatMessage({
              defaultMessage: "Zapamiętaj mnie",
              id: "9ufjkW",
            })}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ my: 2 }}
            disabled={login.isLoading}
          >
            <FormattedMessage defaultMessage="Zaloguj się" id="kYs7ww" />
          </Button>

          <Divider>
            <FormattedMessage defaultMessage="lub" id="nVMaPJ" />
          </Divider>

          <Link href="/register" underline="none">
            <Button
              fullWidth
              sx={{ my: 2 }}
              variant="text"
              disabled={login.isLoading}
            >
              <FormattedMessage defaultMessage="Zarejestruj się" id="ARRFrr" />
            </Button>
          </Link>
        </Box>
      </AuthLayout>
    </>
  );
}

Login.noLayout = true;
