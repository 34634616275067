import { Box, Container } from "@mui/material";
import { PropsWithChildren } from "react";
import { Link } from "../Link";
import Image from "next/image";

export function AuthLayout({ children }: PropsWithChildren<{}>) {
  return (
    <Box>
      <Box sx={{ mt: 4, ml: 5 }}>
        <Link href="/" passHref>
          <Image src="/logo.png" alt="logo" width="56" height="56" />
        </Link>
      </Box>

      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {children}
        </Box>
      </Container>
    </Box>
  );
}
